@font-face {
  font-family: HK Grotesk;
  font-display: swap;
  src: url(./assets/fonts/HKGrotesk-Regular.woff2) format('woff2'),
    url(./assets/fonts/HKGrotesk-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: HK Grotesk;
  font-display: swap;
  src: url(./assets/fonts/HKGrotesk-Medium.woff2) format('woff2'),
    url(./assets/fonts/HKGrotesk-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: HK Grotesk;
  font-display: swap;
  src: url(./assets/fonts/HKGrotesk-Bold.woff2) format('woff2'),
    url(./assets/fonts/HKGrotesk-Bold.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}

@mixin font-stack($size, $weight, $line-height, $text-align, $color) {
  font-family: 'HK Grotesk', sans-serif;
  font-style: normal;
  font-size: $size;
  font-weight: $weight;
  line-height: $line-height;
  text-align: $text-align;
  color: $color;
}

@mixin display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

@mixin display-grid($grid-template-columns, $grid-template-rows, $rowgap) {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  row-gap: 23px;
  justify-items: center;
  align-items: center;
}

@mixin card-props($transition) {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  transition: $transition;
}

$white: #ffffff;
$navy700: #b1bdcd;
$navy1200: #223e67;
$grey60: #495770;
$grey600: #bfc4cb;
$grey1000: #5b6069;
$brand-primary500: #0072ef;
$brand-primary-light: #39bbff;
$crimson-500: #e70f27;

$transition-card: height 0.25s ease-in-out;

* {
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  border: 0;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

:root {
  font-size: 16px;
}

body {
  @include display-flex();
  min-height: 100vh;
  min-width: 100vw;
}

.background {
  background-image: url(./assets/images/background.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: fixed;
  top: 25%;
  left: 0%;
  bottom: 0;
  z-index: -1;
}

.container {
  @include display-flex();
  flex-flow: column;

  & .powered-by-sine {
    margin: 40px 0;
  }
}

.container-card {
  width: 275px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px;
}

.card-landing {
  @include card-props(none);
}

.container-form {
  width: 275px;
  height: 600px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 3.5fr 5fr 3fr 2fr;
  justify-items: center;
  align-items: center;
  padding: 40px;
}

.container-input {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 90px 90px 90px 70px;
  row-gap: 8px;
  justify-items: center;
  align-items: center;
}

.container-img {
  align-self: start;
}

.input-form {
  display: grid;
  align-items: center;
  justify-self: center;
  align-self: start;
}

h1 {
  @include font-stack(1.5625rem, bold, 30px, center, $navy1200);
  align-self: start;
}

h2 {
  @include font-stack(1rem, 500, 19px, left, $navy1200);
}

.step {
  display: inline-flex;

  & h2 {
    width: 180px;
  }

  & img {
    margin-right: 10px;
    margin-left: 5px;
  }
}

input {
  &.error {
    border-color: $crimson-500;
  }

  &::placeholder {
    color: $grey600;
  }

  &:focus {
    border: 1px solid $brand-primary500;
    outline: 0;
  }

  width: 274px;
  height: 46px;
  background: $white;
  border: 1px solid $navy700;
  box-sizing: border-box;
  border-radius: 3px;
  margin-top: 8px;
  padding-left: 10px;
  @include font-stack(1rem, 500, 17px, left, $grey1000);
}

.container-input div:nth-of-type(3) {
  & .PhoneInput {
    position: relative;
    &.error input {
      border-color: $crimson-500;
    }
  }
  & .PhoneInputCountry {
    position: absolute;
    top: 8px;
    bottom: 0;
    left: 0;
    margin-left: 15px;
  }
  & input {
    padding-left: 60px;
    padding-top: 3px;
  }
  & .PhoneInputCountrySelectArrow {
    margin-left: 8px;
  }
}

label {
  @include font-stack(0.875rem, bold, 17px, left, $grey60);
  justify-self: start;
}

.terms {
  @include font-stack(0.8125rem, 500, 140%, center, $navy1200);
  width: 210px;
}

.terms a:link,
a:visited,
a:hover,
a:active {
  color: $brand-primary500;
  text-decoration: none;
}

small {
  @include font-stack(0.75rem, 500, 17px, left, $crimson-500);
  padding-top: 2px;
  display: none;
}

.form-control {
  display: grid;
}

button {
  padding: 8px 26px;
  margin-top: 30px;
  width: auto;
  height: 40px;
  background: $brand-primary500;
  border-radius: 40px;
  align-self: center;
  position: static;
  @include font-stack(1.125rem, bold, 24px, center, $white);
  align-self: end;
  cursor: pointer;

  &:active {
    background: $brand-primary-light;
  }

  &:focus {
    outline: 0;
  }
}

.card-declaration {
  @include card-props($transition-card);

  h2 {
    width: 150px;
    font-weight: bold;
    margin-bottom: 35px;
    align-self: flex-start;
  }

  ul {
    max-width: 255px;
    padding-left: 20px;
    list-style-position: outside;
    @include font-stack(1rem, 500, 24px, left, $navy1200);
  }

  li + li {
    margin-top: 30px;
  }

  a {
    margin-right: 30px;
    @include font-stack(1rem, bold, 140%, center, $navy1200);
    text-decoration: none;
  }
}

.card-not-allowed {
  @include card-props($transition-card);
  h2 {
    @include font-stack(1rem, bold, 20px, center, $navy1200);
    margin-bottom: 35px;
    width: 150px;
  }

  p {
    max-width: 260px;
    @include font-stack(1rem, 500, 20px, center, $navy1200);
  }

  button {
    align-self: center;
    margin-top: 35px;
  }
}

.card-qr {
  @include card-props($transition-card);
  #qr-container {
    margin: 30px 0;
  }
}

.reminder {
  @include font-stack(0.875rem, 500, 140%, center, $navy1200);
  width: 180px;
}

#qrcode img,
canvas {
  max-width: 240px;
}

.hidden {
  transition: opacity 1s ease-out;
  opacity: 0;
  overflow: hidden;
  display: none;
}

.visible {
  opacity: 1;
  display: grid;
}

.sine-pro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  & p {
    @include font-stack(0.875rem, 500, 140%, center, $white);
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 900px) {
  body {
    min-height: 0;
  }
  .container {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 345px) {
  input {
    width: 240px;
  }

  .container-form, .container-card {
    width: 265px;
    padding: 40px 20px;
  }
}

@media screen and (orientation: landscape) {
  #qrcode img,
  canvas {
    max-width: 200px;
  }
  .container {
    margin-top: 40px;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 897px) and (orientation: landscape) {
  .background {
    background-image: url(./assets/images/background.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: fixed;
    top: 25%;
    left: 0%;
    bottom: 0;
    z-index: -1;
  }

  .container-qr {
    margin-top: 0px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    & .step,
    .reminder,
    button,
    .sine-pro,
    a {
      display: none;
    }
  }

  .container-qrcode {
    width: 240px;
    height: 240px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    margin: auto;
  }

  .card-qr {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
